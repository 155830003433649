<template>
  <div>
    <div class="header-container">
      <NavBarNew background/>
      <div class="container">
        <h1 class="tr__h1">{{ $t("filter.searchingForTrips") }}</h1>
      </div>
    </div>

    <div class="main-container">
      <div class="container" style="overflow: visible">
        <TripFilter ref="tripFilterComponent" extended/>
        <div id="search-status" class="pt-5 pb-4">
          <p class="text-left" v-if="travelTipsActual && travelTipsActual.length === 0 && !$apollo.loading && $store.state.sliderMaxValue">{{ $t("filter.noResults") }}</p>
          <p v-if="!hasFilterChanged && travelTipsActual.length > 0" >
            <i18n path="filter.resultFoundTrips" :tag="false">
              <template v-slot:tripCount>
                <strong >{{ $tc('general.travelTipAmmount', travelTipsActualCount) }}</strong>
              </template>
            </i18n>
          </p>
          <div class="view-options">
            <button @click="view = 'CARD'" class="img-button card-view-icon" :class="{active: view == 'CARD'}"></button>
            <span class="seperator-line"></span>
            <button @click="view = 'MAP'" class="img-button map-icon" :class="{active: view == 'MAP'}"></button>
          </div>
          <SearchResult class="mt-4" v-if="!hasFilterChanged && view == 'CARD'"></SearchResult>
          <SearchResultMap class="mt-4" v-show="view == 'MAP' && travelTipsActual.length > 0" :travelTips="travelTipsActualResult" :showMap="view == 'MAP'"/>
          
          <div class="page-loading-container">
            <div v-if="$apollo.loading || !$store.state.sliderMaxValue" class="loader-1 center paging-loading"><span></span></div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
    <div class="to-top-arrow" @click="scrollToTop"></div>
  </div>

</template>

<script>

import TripFilter from "@/components/TripFilter";
import Footer from "@/components/Footer";
import {TRAVEL_TIPS_ACTUAL, TRAVEL_TIPS_ACTUAL_COUNT} from "@/api/graphql/query/FilterQuery";
import SearchResult from "@/components/SearchResult";
import { assignTravelTipLocalizationProperties } from "../utils/LocalizationDataConverter";
import SearchResultMap from "../components/SearchResultMap.vue";

const SLIDER_VALUE_DEFAULT = [1, 10]
const SINGLE_PAGE_SIZE = 20

export default {
  name: "TravelTipListPage",
  components: {
    SearchResult,
    TripFilter,
    Footer,
    SearchResultMap
  },

  data: function () {
    return {
      travelTipsActual: [],
      sliderValueDefault: SLIDER_VALUE_DEFAULT,
      isMoreToFetch: true,
      hasFilterChanged: true,
      view: "CARD",
    }
  },

  created() {
    // if accessing search page through url, filter in store would be null
    if (this.$store.state.currentFilter === null) {
      this.$store.commit('setDefaultFilter')
    }

    this.$store.commit('setDefaultPage')

    this.setFilterFromUrlParam();
    if (this.$store.state.sliderMaxValue != undefined) {
      this.$apollo.queries.travelTipsActual.start();
      this.$apollo.queries.travelTipsActualCount.start();
    }

    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  computed: {
    travelTipsActualResult: function () {
      return this.$store.state.travelTipsActual;
    },
    resultRefresh() {
      return this.$store.state.resultRefresh;
    },
    preferredLanguage() {
      return this.$store.state.preferredLanguage
    }
  },
  mounted() {
    setTimeout(() => {
      this.scrollToResults()
    }, 100);
  },

  watch: {
    preferredLanguage() {
      this.$store.commit("setDefaultPage")
      this.isMoreToFetch = true
    },
    resultRefresh() {
      if (JSON.stringify(this.lastFilterUsed) != JSON.stringify(this.$store.state.currentFilter)) {
        this.hasFilterChanged = true
        this.isMoreToFetch = true
        this.$store.commit("setDefaultPage")
      }
      this.scrollToResults()
      this.$apollo.queries.travelTipsActual.start();
      this.$apollo.queries.travelTipsActualCount.start();
    },
  },

  apollo: {
    travelTipsActualCount: {
      query: TRAVEL_TIPS_ACTUAL_COUNT,
      
      variables: function() {
        return {
          filter: this.$store.state.currentFilter
        }
      },
      skip: true,
      fetchPolicy: 'network-only',
    },
    travelTipsActual: {
      query: TRAVEL_TIPS_ACTUAL,

      variables: function () {
        return {
          filter: this.$store.state.currentFilter,
          
          pageSize: {
              page: 0,
              size: SINGLE_PAGE_SIZE,
              sortAttribute: 'VIEW_COUNT',
              sortDirection: 'DESC'
          }
        }
      },
      skip: true,
      fetchPolicy: 'network-only',
      result ({ data, loading, networkStatus }) {
        this.lastFilterUsed = {...this.$store.state.currentFilter}
        this.hasFilterChanged = false
        this.$store.commit('setTravelTipsActual', [...data.travelTipsActual.map(travelTip => assignTravelTipLocalizationProperties(travelTip))]);

        if (data.travelTipsActual.length < SINGLE_PAGE_SIZE) {
          this.isMoreToFetch = false
        }
        history.replaceState(null, null, this.createFilterUrlParam());
      },
      error (error) {
        console.log('We\'ve got an error!', error)
      }
    },
  },

  methods: {
    scrollToResults() {
      document.querySelector(".main-container").scrollIntoView({
          behavior: "smooth"
      });
    },
    showButtonToTop() {
      if (this.throttleTimer) return;
        this.throttleTimer = true;
        setTimeout(() => {
            const scrollButton = document.querySelector('.to-top-arrow')
            if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
              scrollButton.classList.add("show");
            } else {
              scrollButton.classList.remove('show');
            }
            this.throttleTimer = false;
          }, 100);
    },
    scrollToTop() {
      window.scrollTo({top: 0, behavior: "smooth"})
    },
    handleScroll() {
      this.showButtonToTop()
      if (!this.isMoreToFetch || this.$apollo.loading || this.view === "MAP") return
      const scrollThreshold = 900;
      
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - scrollThreshold) {
        this.fetchMoreTravelTips();
      }
    },
    fetchMoreTravelTips () {
      this.$store.commit('setNextPage')

      this.$apollo.queries.travelTipsActual.fetchMore({
        variables: {
          filter: this.lastFilterUsed,
          pageSize: this.$store.state.currentPageSize
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult.travelTipsActual.length < SINGLE_PAGE_SIZE) {
            this.isMoreToFetch = false
          }

          return {
            travelTipsActual: [
              ...previousResult.travelTipsActual,
              ...fetchMoreResult.travelTipsActual,
            ],
          }
        },
      })
    },
    createFilterUrlParam: function () {
      const params = new URLSearchParams();
      if ((this.$store.state.currentFilter.countries != null)
          && (this.$store.state.currentFilter.countries.length > 0))
        params.append('countries', this.$store.state.currentFilter.countries);
      if (this.$store.state.currentFilter.themes != null
          && this.$store.state.currentFilter.themes.length > 0)
        params.append('theme', this.$store.state.currentFilter.themes);
      if (this.$store.state.currentFilter.difficulties != null
          && this.$store.state.currentFilter.difficulties.length > 0)
        params.append('difficulty', this.$store.state.currentFilter.difficulties);
      if ((this.$store.state.currentFilter.transferTypes != null)
          && (this.$store.state.currentFilter.transferTypes.length > 0))
        params.append('transferTypes', this.$store.state.currentFilter.transferTypes);
      if ((this.$store.state.currentFilter.minLengthOfTravel != null)
          && (this.$store.state.currentFilter.minLengthOfTravel !== SLIDER_VALUE_DEFAULT[0]))
        params.append('minLengthOfTravel', this.$store.state.currentFilter.minLengthOfTravel);

      // TODO handle dynamic maxLengthOfTravel..
      // if ((this.$store.state.currentFilter.maxLengthOfTravel != null)
      //     && (this.$store.state.currentFilter.maxLengthOfTravel !== SLIDER_VALUE_DEFAULT[1]))
      //       params.append('maxLengthOfTravel', this.$store.state.currentFilter.maxLengthOfTravel);
      if ((this.$store.state.currentFilter.searchString != null)
          && (this.$store.state.currentFilter.searchString.length > 0))
        params.append('searchString', this.$store.state.currentFilter.searchString);

      return "/#" + this.$route.path + "?" + params.toString();
    },

    setFilterFromUrlParam: function () {
      let filter = this.$store.state.currentFilter;
      if (this.$route.query.countries) filter.countries = this.$route.query.countries.split(',');
      if (this.$route.query.theme) filter.themes = this.$route.query.theme.split(',');
      if (this.$route.query.difficulty) filter.difficulties = this.$route.query.difficulty.split(',');
      if (this.$route.query.transferTypes) filter.transferTypes = this.$route.query.transferTypes.split(',');
      if (this.$route.query.minLengthOfTravel) filter.minLengthOfTravel = this.$route.query.minLengthOfTravel;
      if (this.$route.query.maxLengthOfTravel) filter.maxLengthOfTravel = this.$route.query.maxLengthOfTravel;
      if (this.$route.query.searchString) filter.searchString = this.$route.query.searchString;
      this.$store.commit('setCurrentFilter', filter);
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

  .to-top-arrow {
    &.show {
      bottom: 30px;
    }
    @media screen and (max-width: 576px) {
      right: 20px;
      height: 50px;
      width: 50px;

      &.show {
        bottom: 20px
      }
    }
  }

.view-options {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button, span {
    width: 24px;
    height: 24px;
    opacity: 0.4;
    
    &.active {
      opacity: 1;
    }
  }

  .seperator-line {
    width: 1px;
    background-color: $tr-black;
  }

  .card-view-icon {
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)" stroke="%2329414D"><path d="M5.5 13.5h5v6h-5zm8 0h5v6h-5zm-8-9h5v6h-5zm8 0h5v6h-5z"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
  }

  .map-icon {
    background-image: url('data:image/svg+xml,<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><circle cx="17" cy="18" r="1" fill="%2329414D"/><path d="m12.707 6 4.561 1.974L21 6v13.026L17.268 21l-4.56-1.974L8.145 21 4 19.026V6l4.5 1.974" stroke="%2329414D"/><path d="M12.5 6c0 .29-.14.72-.413 1.251a13 13 0 0 1-.993 1.578A25 25 0 0 1 10 10.233l-.084-.1a25 25 0 0 1-1.01-1.304 13 13 0 0 1-.993-1.578C7.64 6.72 7.5 6.29 7.5 6a2.5 2.5 0 0 1 5 0Z" stroke="%2329414D"/><circle cx="10" cy="13" r="1" fill="%2329414D"/><circle cx="10" cy="6" r="1" fill="%2329414D"/><path d="M8 21v-9.5m5 7.5V6m4 10V8" stroke="%2329414D"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
  }
}

.page-loading-container {
  margin-top: 50px;
}

@media (min-width: 1470px){
  .container {
    max-width: 1440px;
  }
}

h1 {
  text-align: left;
  font-weight: bold;
  margin-top: 120px;
}

p {
  text-align: left;
}

.header-container {
  background-color: $tr-green;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.main-container {
  min-height: 60vh;
  margin-top: 160px;
  padding-top: 20px;
  padding-bottom: 120px;
  background-color: $tr-white-alt;

  @media (max-width: 899px) {
    margin-top: 120px;
  }
}
</style>
