<template>
  <div class="travel-tip-list">
    <router-link v-for="travelTip in travelTipsActual" :key="travelTip.id" class="travel-tip"
                  :to="{name:'travelTipDetail', params:{travelTipId:travelTip.id}}">
      <TripItem :trip='travelTip'/>
    </router-link>
  </div>
</template>

<script>
import TripItem from "@/components/redesign/TripItem";

export default {
  name: "SearchResult",

  components: {
    TripItem,
  },

  computed: {
    travelTipsActual: function () {
      return this.$store.state.travelTipsActual;
    }
  },

}
</script>

<style scoped lang="scss">

  .travel-tip-list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(280px, auto));
    
    @media (max-width: 576px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, auto));
    }
  }

  .travel-tip {
    text-decoration: none;
  }

</style>