<template>
    <section class="travel-tip-detail-page-modal-body">
        <div class="map-loading" v-if="noItemsError">{{ $t("loadingErrors.mapNoItems") }}</div>
        <div class="map-loading" v-else-if="itemWithoutLocationError">{{ $t("loadingErrors.mapMissingItems") }}</div>
        <div class="map-loading" v-else-if="unexpectedError">{{ $t("loadingErrors.mapUnknown") }}</div>
        <div class="map-loading" v-else-if="isMapLoading">{{ $t("loading.map") }}</div>
        <div id="map" class="public-map map"></div>
        <div v-if="displayedTravelTip" class="travel-tip-info">
          <div class="picture-container">
            <span role="button" class="close-btn" @click="displayedTravelTip = null">&#215;</span>
            <img :src="imgUrl + displayedTravelTip.titlePicture.fileName.slice(0, displayedTravelTip.titlePicture.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + displayedTravelTip.titlePicture.fileName.slice(displayedTravelTip.titlePicture.fileName.lastIndexOf('.'))" alt="">
          </div>
          <p class="h2 px-3 mt-3">{{ displayedTravelTip.title }}</p>
          <p class="h4 px-3 font-emilio">{{ $tc("general.dayAmmount", displayedTravelTip.itineraryDays.length).toUpperCase() }}</p>
          <div class="px-3 mt-auto">
            <router-link type='button' class="button button--primary" :to="{name:'travelTipDetail', params: {travelTipId: displayedTravelTip.id}}">{{$t("map.showTrip")}}</router-link>
          </div>
        </div>
    </section>
</template>

<script>
import {CLEVERTRIP_IMG_URL} from '@/definitions';
import LazyLoadDirective from "@/components/LazyLoadDirective.js";

    export default {
        name: "Map",
        props: ['travelTips', 'showMap'],
        

        directives: {
            lazyload: LazyLoadDirective
        },

        data() {
            return {
                imgUrl: CLEVERTRIP_IMG_URL,
                noItemsError: false,
                itemWithoutLocationError: false,
                unexpectedError: false,
                isMapLoading: false,
                map: null,
                allTravelTipCoords: [],
                allGeometries: [],
                allMarkers: [],
                displayedTravelTip: null,
                activeMarkerId : null,
                showMarkerInfo: false,
                travelTipsChanged: true,
            }
        },

        mounted() {
            /*eslint-disable*/
            Loader.async = true
            Loader.load(null, null);
            Loader.lang = "cs";
            /*eslint-enable*/
        },

        watch: {
            travelTips(nV) {
                this.travelTipsChanged = true
                if (this.showMap) {
                    this.resetAllToDefault()
                }
            },
            showMap(nV) {
                if (nV == null) return 
                if (nV == true && this.travelTipsChanged) {
                    this.resetAllToDefault()
                }
            }
        },

        methods: {
            resetAllToDefault() {
                this.allMarkers = []
                this.noItemsError = false
                this.itemWithoutLocationError = false
                this.unexpectedError = false
                if (this.travelTipsChanged) {
                    const mapEl = document.getElementById("map")
                    mapEl.replaceChildren();
                }
                this.isMapLoading = true
                this.loadMap()
            },
            hideMarkerInfo(syncPort = true) {
                if (this.activeMarkerId) {
                    const iconEl = document.getElementById(this.activeMarkerId)
                    this.activeMarkerId = null
                    iconEl.style.zIndex = ""
                    iconEl.childNodes[1].style.display = "none"
                }
                this.showMarkerInfo = false
                if (syncPort) {
                    this.$nextTick(() => {
                        this.map.syncPort(true)
                    })
                }
            },

            createMarker(travelTip) {
                /*eslint-disable*/
                let _this = this
                let travelTipCoords = travelTip.location
                let markerElement = JAK.mel("div", {}, {cursor: "pointer"});
                markerElement.id = travelTip.id
                
                function highlightMarker(e, isVisible, element = null) {
                    if (!isVisible && _this.activeMarkerId == travelTip.id) return
                    const iconEl = element ? element : e.target.parentElement
                    iconEl.style.zIndex = isVisible ? 1 : ""
                    iconEl.childNodes[1].style.display = isVisible ? "block" : "none"
                    iconEl.childNodes[0].classList[isVisible ? 'add' : 'remove']('active')
                }

                markerElement.addEventListener("click", function(e) {
                    _this.displayedTravelTip = travelTip

                    // If there is active marker and it isn't this one -> unhighlight it 
                    if (_this.activeMarkerId != null && _this.activeMarkerId != travelTip.id) {
                        const markerEl = document.getElementById(_this.activeMarkerId)
                        highlightMarker(e, false, markerEl)                        
                    }

                    _this.activeMarkerId = travelTip.id
                    highlightMarker(e, true)

                    // rerenderElement for image lazyload directive to work correctly 
                    if (!_this.showMarkerInfo) {
                        _this.showMarkerInfo = true
                        setTimeout(() => {
                            _this.map.syncPort(true)
                        }, 300);
                    }
                    _this.map.setCenter(travelTipCoords, true)
                })
            
                let markerIcon = JAK.mel("div");
                markerIcon.classList.add("start-icon")
                // let markerIcon = JAK.mel("div", {}, {position: "absolute", top: "-15px", left: "-6px", width: "32px", height: "32px", border: "2px solid white", backgroundColor: "#3BEA7E", borderRadius: "50%", filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)"});

                let markerTitle = JAK.mel("div", {}, {position: "absolute", color: "#222", zIndex: "1", display: "none", top: "-13px", left: "32px", width: "max-content", lineHeight: "20px", padding: "4px 10px", height: "28px", backgroundColor: "#3BEA7E", filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)"});
                markerTitle.innerHTML = travelTip.title

                // Show item title on mouseover
                JAK.Events.addListener(markerIcon, "mouseover", (e) => highlightMarker(e, true))
                JAK.Events.addListener(markerIcon, "mouseout", (e) => highlightMarker(e, false))

                markerElement.appendChild(markerIcon);
                markerElement.appendChild(markerTitle)
                if (typeof(markerText) != "undefined") {
                    markerElement.appendChild(markerText);
                }
                return new SMap.Marker(travelTipCoords, null, {url: markerElement, anchor: { left: 10, bottom: 1 } })  
                /*eslint-enable*/
            },

            loadMap: async function () {
                try {
                    /*eslint-disable*/
                    // if (!Array.isArray(this.travelTip.itineraryDays) || !this.travelTip.itineraryDays.length)  return this.noItemsError = true;
                    let center = SMap.Coords.fromWGS84(50.073658, 14.418540);
                    this.map = new SMap(JAK.gel('map'), center, 16);
                    this.map.setZoomRange(4, 18)
                    this.map.addDefaultLayer(SMap.DEF_BASE).enable();
                    this.map.addControl(new SMap.Control.Mouse(SMap.MOUSE_PAN | SMap.MOUSE_WHEEL | SMap.MOUSE_ZOOM, {minDriftSpeed:1/0}));
                    this.map.addControl(new SMap.Control.Keyboard(SMap.KB_PAN | SMap.KB_ZOOM, {focusedOnly:false}));
                    this.map.addControl(new SMap.Control.Selection(2));
                    this.map.addControl(new SMap.Control.Scale(), {bottom:"32px",left:"8px"});
                    this.map.addControl(new SMap.Control.Zoom({}, { titles: ["Přiblížit", "Oddálit"], showZoomMenu: false }), {right: "10px", top: "10px"});
                    this.isMapLoading = false
                    this.allTravelTipCoords = []
                    this.travelTipsChanged = false

                    for (const [travelTipIndex, travelTip] of this.travelTips.entries()) {
                        let travelTipMarkerCoords = null

                        let itineraryDays = [...travelTip.itineraryDays].sort((a,b)=> a.dayNumber -b.dayNumber)
                        if (itineraryDays[0].itineraryItems.length < 1) return this.noItemsError = true
                        // if (!itineraryDays[0].itineraryItems[0].location) return this.itemWithoutLocationError = true

                        let itineraryItems = [...itineraryDays[0].itineraryItems]
                        itineraryItems.sort((a, b) => {
                            if (a.itineraryItemType === "START" || b.itineraryItemType === 'FINISH') return -1
                            else if (b.itineraryItemType === "START" || a.itineraryItemType === 'FINISH') return 1
                            else return a.itemNumber - b.itemNumber;
                        })

                        for (const itineraryItem of itineraryItems) {
                            if (!travelTipMarkerCoords && itineraryItem.location) {
                              travelTipMarkerCoords = SMap.Coords.fromWGS84(itineraryItem.location.longitude, itineraryItem.location.latitude);
                              this.allTravelTipCoords.push(travelTipMarkerCoords)
                              break
                            }
                        }

                        // Vytvořit marker k travelTipu
                        this.allMarkers[travelTipIndex] = new SMap.Layer.Marker();
                        this.map.addLayer(this.allMarkers[travelTipIndex]).enable();

                        const marker = this.createMarker({id: travelTip.id, title: travelTip.title, titlePicture: travelTip.titlePicture, itineraryDays: travelTip.itineraryDays, description: travelTip.description, location: travelTipMarkerCoords ? travelTipMarkerCoords : center})
                        this.allMarkers[travelTipIndex].addMarker(marker);
                    }

                    let centerZoom = this.map.computeCenterZoom(this.allTravelTipCoords);
                    this.map.setCenterZoom(centerZoom[0], centerZoom[1]);
                } catch (error) {
                    console.log(error)
                    this.unexpectedError = true            
                }
                /*eslint-enable*/
            },
        },

    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.image-container {
  max-height: 230px;
  width: 40%;
  padding: 20px 20px 0 0;
  position: relative;

  figure {
    margin: 0;
    margin-inline: auto;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.travel-tip-info {
  max-width: 400px;
  width: 80%;
  height: 100%;
  box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.25);
  background-color: $tr-white;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  z-index: 2;
  border: 1px solid $tr-black;

  .picture-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 50%;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .close-btn {
    font-size: 25px;
    width: 30px;
    height: 30px;
    right: 0;
    line-height: 30px;
    text-align: center;
    position: absolute;
    background-color: $tr-white;
  }

  a {
    margin-top: auto;
    margin-bottom: 60px;
  }

}

.travel-tip-detail-page-modal-body {
  position: relative;
  touch-action: none;
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;

  .day-pick-buttons {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 125px);
    height: 27px;
    gap: 10px;
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 10px;

    button {
      height: 100%;
      padding-block: 0;
      box-shadow: 0 0 2px 0 rgba(0,0,0,.3) !important;
      background-color: $tr-white;
      color: $tr-black;
      padding-inline: 6px;
      border: none;

      &.disabled {
        background-color: #e1e1e1 !important;
        cursor: not-allowed;
        color: #bcbcbc !important; 
      }

      &.active {
        background-color: $tr-green !important;
        color: $tr-black !important;
      }

      &:hover {
        color: $tr-green;
      }
    }

    & > div {
      height: 100%;
      padding-block: 0;
      box-shadow: 0 0 2px 0 rgba(0,0,0,.3) !important;
      background-color: $tr-white;
      color: $tr-black;
      border: none;
      padding-inline: 7px;

      a {
        color: $tr-black;
        text-decoration: underline;
        vertical-align: middle;

        &:hover {
          color: #606060;
        }
      }
    }
  }

  & .map-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $tr-white;
    color: $tr-black;
    padding: 5px 10px;
    z-index: 5;
    width: max-content;
    max-width: 100%;
    text-align: center;
  }
}

.map {
  width: 100%;
  height: 100%;
  min-height: 75vh;
  transition: height 0.2s;
  background-color: $tr-white;
}

</style>

<style lang="scss">
    @import '@/scss/variables';

.smap .zoom .button-group {
  border-radius: 0 !important;
  button {
    color: $tr-black !important;

    &:hover {
      color: $tr-green !important;
      background-color: $tr-white !important;
    }
  }
}
.smap.public-map img[src*="//api.mapy.cz/v"]{
    filter: grayscale(100%)  brightness(1.1) contrast(1.05);
}
.smap .noprint a, .smap .noprint div {
    z-index: 50;
}

.smap .start-icon {
    position: absolute;
    width: 30px;
    height: 40px;
    top: -22px;
    left: -4px;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    background-repeat: no-repeat;
    background-size: 30px 40px;
    background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%233BEA7E" stroke="%23fff" stroke-width="2"/><path d="m11 16.5 10.292-3.958L11 8.583V16.5ZM11 7v19" stroke="%23fff"/></svg>') !important;

    &.active {
        background-image: url('data:image/svg+xml,<svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 14.94c0 1.813-.841 4.232-2.233 6.94-1.375 2.675-3.22 5.505-5.08 8.102a125.386 125.386 0 0 1-5.115 6.626c-.635.771-1.174 1.407-1.572 1.87-.398-.464-.937-1.099-1.572-1.87a125.4 125.4 0 0 1-5.115-6.626c-1.86-2.597-3.705-5.427-5.08-8.102C1.841 19.172 1 16.753 1 14.94 1 7.245 7.264 1 15 1s14 6.245 14 13.94Z" fill="%233BEA7E" stroke="%23fff" stroke-width="2"/><path d="m11 16.5 10.292-3.958L11 8.583V16.5ZM11 7v19" stroke="%23fff"/></svg>') !important;
    }
}

.smap svg {
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.smap g {
  cursor: pointer;
}
</style>
